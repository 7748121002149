export const colorTextStatus = (item: string) => {
  switch (item) {
    case 'Assinar termo':
    case 'Assinar contrato':
    case 'Contrato Ademicon':
    case 'Enviar termo de confidencialidade':
      return '#FEEBB2'
    case 'Análise PJ':
    case 'Reanálise PF':
    case 'Reanálise PJ':
    case 'Defesa':
    case 'Retificação de documentos PF':
    case 'Retificação de documentos PJ':
      return '#ABDEF5'
    case 'Não selecionado':
    case 'Desistência':
    case 'Desistência confirmada':
      return '#D9D9D9'
    case 'Matricular UCA':
    case 'Aguardando documentação e finalizar cursos':
      return '#F9C995'
    case 'Análise vencida':
      return '#E8AEBA'
    case 'Em análise':
      return '#F4BDAB'
    case 'Processo finalizado':
    case 'Matrículas e acessos':
    case 'Confecção de matrícula e acessos':
      return '#A2E8CA'
    case 'Comentário público':
    case 'Comentário confidencial':
      return '#B6C0F2'
    case 'Migração':
      return '#D9D9D9'
    case 'Blacklist':
      return '#E8AEBA'
    default:
      return ''
  }
}
