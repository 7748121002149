import apiV2 from 'store/utils/apiv2'
import { put, call, takeLatest, all, fork } from 'redux-saga/effects'

import {
  getBlacklistSuccess,
  getBlacklistFailure,
  getCotatradeEngineQuotasSuccess,
  getCotatradeEngineQuotasFailure,
} from './actions'
import { Category, CategoryChangeData } from './interface'
import { ComtemplayActionsTypes } from './types'

export const getAll = async (): Promise<any> => {
  const res = await apiV2.get('/cotatrade/backstage')
  return res
}

export const getById = async (id: string): Promise<any> => {
  const res = await apiV2.get(`/cotatrade/backstage/${id}`)
  return res
}

export const create = async (data: any): Promise<any> => {
  const res = await apiV2.post(`/cotatrade/backstage`, data)
  return res
}

export const approve = async (id: string, data: any): Promise<any> => {
  const res = await apiV2.put(`/cotatrade/backstage/${id}/check`, data)
  return res
}

export const sendEmail = async (id: string, data: any): Promise<any> => {
  const res = await apiV2.post(`/cotatrade/backstage/email/${id}`, data)
  return res
}

export const getFilters = async (): Promise<any> => {
  const res = await apiV2.get(`/cotatrade/categories`)
  return res
}

export const addBlacklist = async (data: { category: Category; quotas: string }): Promise<any> => {
  const quotas = data.quotas
    .split(',')
    .filter((qt) => !!qt)
    .join(',')
  const res = await apiV2.post(`/cotatrade/blacklist/backstage/add`, { ...data, quotas })
  return res
}

export const deleteBlacklist = async (body: any): Promise<any> => {
  const res = await apiV2.delete(`/cotatrade/blacklist/backstage/remove`, { data: { data: body } })
  return res
}

export const getBlacklist = async (size: number): Promise<any> => {
  return await apiV2.get(`/cotatrade/blacklist/backstage/getall?page=1&size=${size}`)
}

export const getHistoric = async (idQuota: string): Promise<any> => {
  return await apiV2.get(`/cotatrade/quotas/backstage/${idQuota}/transfer/history`)
}

export const validateQuota = async (data: CategoryChangeData): Promise<any> => {
  return await apiV2.post('/cotatrade/quotas/backstage/validate/blacklist', data)
}

export const changeCategory = async (data: CategoryChangeData): Promise<any> => {
  return await apiV2.post('/cotatrade/quotas/backstage/category/update', data)
}

//Handlers
export function* getBlacklistedQuotas(action: any) {
  const { body } = action.payload
  try {
    const filters: Category[] = body.filters
    let filtersQs = ''
    if (filters) {
      filters.forEach((category) => {
        filtersQs += `&category[]=${category}`
      })
    }
    let idQuotaQs = ''
    if (body.idQuota) {
      idQuotaQs = `&idQuota=${body.idQuota.trim()}`
    }
    let order = ''
    if (body.order) {
      order = `&order=${body.order}`
    }
    const res: any = yield call(
      apiV2.get,
      `/cotatrade/blacklist/backstage/getall?page=${body.page}&size=${body.size}${filtersQs}${idQuotaQs}${order}`,
    )
    yield put(getBlacklistSuccess(res))
  } catch (err) {
    yield put(getBlacklistFailure())
  }
}

export function* getEngineQuotas(action: any) {
  const { body } = action.payload
  try {
    const filters: Category[] = body.filters
    let filtersQs = ''
    if (filters) {
      filters.forEach((category) => {
        filtersQs += `&category[]=${category}`
      })
    }
    let idQuotaQs = ''
    if (body.idQuota) {
      idQuotaQs = `&idQuota=${body.idQuota.trim()}`
    }
    let order = ''
    if (body.order) {
      order = `&order=${body.order}`
    }
    const res: any = yield call(
      apiV2.get,
      `/cotatrade/quotas/backstage/motor?page=${body.page}&size=${body.size}${filtersQs}${idQuotaQs}${order}`,
    )
    yield put(getCotatradeEngineQuotasSuccess(res))
  } catch (err) {
    yield put(getCotatradeEngineQuotasFailure())
  }
}

function* watchSagas(): Generator {
  yield takeLatest(ComtemplayActionsTypes.LIST_BLACKLISTED_QUOTAS_REQUEST, getBlacklistedQuotas)
  yield takeLatest(ComtemplayActionsTypes.LIST_COTATRADE_ENGINE_REQUEST, getEngineQuotas)
}

export default function* contemplaySagas() {
  yield all([fork(watchSagas)])
}
