import {
  ListState,
  ListRelatorioState,
  UserTermState,
  HistoryState,
  SendCommentState,
  UnitsState,
  UploadPrevias,
  DocumentsState,
  SendCommentStatusState,
  UclaFlagState,
  WitnessState,
  UpdateUserPjState,
  ProrrogarState,
  BanksState,
  SetDocumentStatus,
  ArquivarPreviaState,
  ContratoDetailState,
  TermConfidentialityState,
  CancelTermConfidentialityState,
  BackStageFlags,
  DocTypes,
  User,
  Witness,
  UserPj,
} from './interface'

export const PreviasTypes = {
  GET_LIST_REQUEST: '@@previas/GET_LIST_REQUEST',
  GET_LIST_SUCCESS: '@@previas/GET_LIST_SUCCESS',
  GET_LIST_FAILURE: '@@previas/GET_LIST_FAILURE',
  GET_UNITS_REQUEST: '@@previas/GET_UNITS_REQUEST',
  GET_UNITS_SUCCESS: '@@previas/GET_UNITS_SUCCESS',
  GET_UNITS_FAILURE: '@@previas/GET_UNITS_FAILURE',
  GET_BANKS_REQUEST: '@@previas/GET_BANKS_REQUEST',
  GET_BANKS_SUCCESS: '@@previas/GET_BANKS_SUCCESS',
  GET_BANKS_FAILURE: '@@previas/GET_BANKS_FAILURE',
  GET_ROBOTS_REQUEST: '@@previas/GET_ROBOTS_REQUEST',
  GET_ROBOTS_SUCCESS: '@@previas/GET_ROBOTS_SUCCESS',
  GET_ROBOTS_FAILURE: '@@previas/GET_ROBOTS_FAILURE',
  GET_MINUTA_REQUEST: '@@previas/GET_MINUTA_REQUEST',
  GET_MINUTA_SUCCESS: '@@previas/GET_MINUTA_SUCCESS',
  GET_MINUTA_FAILURE: '@@previas/GET_MINUTA_FAILURE',
  GET_WITNESS_REQUEST: '@@previas/GET_WITNESS_REQUEST',
  GET_WITNESS_SUCCESS: '@@previas/GET_WITNESS_SUCCESS',
  GET_WITNESS_FAILURE: '@@previas/GET_WITNESS_FAILURE',
  GET_USER_PJ_FAILURE: '@@previas/GET_USER_PJ_FAILURE',
  GET_USER_PJ_REQUEST: '@@previas/GET_USER_PJ_REQUEST',
  GET_USER_PJ_SUCCESS: '@@previas/GET_USER_PJ_SUCCESS',
  GET_UCLA_FLAG_REQUEST: '@@previas/GET_UCLA_FLAG_REQUEST',
  GET_UCLA_FLAG_SUCCESS: '@@previas/GET_UCLA_FLAG_SUCCESS',
  GET_UCLA_FLAG_FAILURE: '@@previas/GET_UCLA_FLAG_FAILURE',
  GET_EMAIL_CORP_REQUEST: '@@previas/GET_EMAIL_CORP_REQUEST',
  GET_EMAIL_CORP_SUCCESS: '@@previas/GET_EMAIL_CORP_SUCCESS',
  GET_EMAIL_CORP_FAILURE: '@@previas/GET_EMAIL_CORP_FAILURE',
  GET_USER_DETAIL_REQUEST: '@@previas/GET_USER_DETAIL_REQUEST',
  GET_USER_DETAIL_SUCCESS: '@@previas/GET_USER_DETAIL_SUCCESS',
  GET_USER_DETAIL_FAILURE: '@@previas/GET_USER_DETAIL_FAILURE',
  GET_ROBOTS_LISTS_REQUEST: '@@previas/GET_ROBOTS_LISTS_REQUEST',
  GET_ROBOTS_LISTS_SUCCESS: '@@previas/GET_ROBOTS_LISTS_SUCCESS',
  GET_ROBOTS_LISTS_FAILURE: '@@previas/GET_ROBOTS_LISTS_FAILURE',
  GET_HISTORY_LIST_REQUEST: '@@previas/GET_HISTORY_LIST_REQUEST',
  GET_HISTORY_LIST_SUCCESS: '@@previas/GET_HISTORY_LIST_SUCCESS',
  GET_HISTORY_LIST_FAILURE: '@@previas/GET_HISTORY_LIST_FAILURE',
  GET_USER_APPROVED_REQUEST: '@@previas/GET_USER_APPROVED_REQUEST',
  GET_USER_APPROVED_SUCCESS: '@@previas/GET_USER_APPROVED_SUCCESS',
  GET_USER_APPROVED_FAILURE: '@@previas/GET_USER_APPROVED_FAILURE',
  GET_ARCHIVED_LISTS_REQUEST: '@@previas/GET_ARCHIVED_LISTS_REQUEST',
  GET_ARCHIVED_LISTS_SUCCESS: '@@previas/GET_ARCHIVED_LISTS_SUCCESS',
  GET_ARCHIVED_LISTS_FAILURE: '@@previas/GET_ARCHIVED_LISTS_FAILURE',
  GET_LIST_RELATORIO_REQUEST: '@@previas/GET_LIST_RELATORIO_REQUEST',
  GET_LIST_RELATORIO_SUCCESS: '@@previas/GET_LIST_RELATORIO_SUCCESS',
  GET_LIST_RELATORIO_FAILURE: '@@previas/GET_LIST_RELATORIO_FAILURE',
  GET_DOCUMENTS_TYPE_REQUEST: '@@previas/GET_DOCUMENTS_TYPE_REQUEST',
  GET_DOCUMENTS_TYPE_SUCCESS: '@@previas/GET_DOCUMENTS_TYPE_SUCCESS',
  GET_DOCUMENTS_TYPE_FAILURE: '@@previas/GET_DOCUMENTS_TYPE_FAILURE',
  GET_CONTRATO_DETAIL_REQUEST: '@@previas/GET_CONTRATO_DETAIL_REQUEST',
  GET_CONTRATO_DETAIL_SUCCESS: '@@previas/GET_CONTRATO_DETAIL_SUCCESS',
  GET_CONTRATO_DETAIL_FAILURE: '@@previas/GET_CONTRATO_DETAIL_FAILURE',
  GET_USER_DETAIL_TERM_REQUEST: '@@previas/GET_USER_DETAIL_TERM_REQUEST',
  GET_USER_DETAIL_TERM_SUCCESS: '@@previas/GET_USER_DETAIL_TERM_SUCCESS',
  GET_USER_DETAIL_TERM_FAILURE: '@@previas/GET_USER_DETAIL_TERM_FAILURE',
  GET_TERM_CONFIDENTIALITY_REQUEST: '@@previas/GET_TERM_CONFIDENTIALITY_REQUEST',
  GET_TERM_CONFIDENTIALITY_SUCCESS: '@@previas/GET_TERM_CONFIDENTIALITY_SUCCESS',
  GET_TERM_CONFIDENTIALITY_FAILURE: '@@previas/GET_TERM_CONFIDENTIALITY_FAILURE',

  SEND_COMMENT_REQUEST: '@@previas/SEND_COMMENT_REQUEST',
  SEND_COMMENT_SUCCESS: '@@previas/SEND_COMMENT_SUCCESS',
  SEND_COMMENT_FAILURE: '@@previas/SEND_COMMENT_FAILURE',

  SEND_DESISTENCIA_REQUEST: '@@previas/SEND_DESISTENCIA_REQUEST',
  SEND_DESISTENCIA_SUCCESS: '@@previas/SEND_DESISTENCIA_SUCCESS',
  SEND_DESISTENCIA_FAILURE: '@@previas/SEND_DESISTENCIA_FAILURE',

  REVERTER_DESISTENCIA: '@@previas/REVERTER_DESISTENCIA',

  SET_MOTIVOS_PADRAO_REQUEST: '@@previas/SET_MOTIVOS_PADRAO_REQUEST',
  SET_MOTIVOS_PADRAO_SUCCESS: '@@previas/SET_MOTIVOS_PADRAO_SUCCESS',
  SET_MOTIVOS_PADRAO_FAILURE: '@@previas/SET_MOTIVOS_PADRAO_FAILURE',

  CREATE_MOTIVO_PADRAO: '@@previas/CREATE_MOTIVO_PADRAO',
  EDIT_MOTIVO_PADRAO: '@@previas/EDIT_MOTIVO_PADRAO',
  DELETE_MOTIVO_PADRAO: '@@previas/DELETE_MOTIVO_PADRAO',

  SET_BLACKLIST_REQUEST: '@@previas/SET_BLACKLIST_REQUEST',
  SET_BLACKLIST_SUCCESS: '@@previas/SET_BLACKLIST_SUCCESS',
  SET_BLACKLIST_FAILURE: '@@previas/SET_BLACKLIST_FAILURE',

  SET_BLACKLIST_UPLOAD_REQUEST: '@@previas/SET_BLACKLIST_UPLOAD_REQUEST',
  SET_BLACKLIST_UPLOAD_SUCCESS: '@@previas/SET_BLACKLIST_UPLOAD_SUCCESS',
  SET_BLACKLIST_UPLOAD_FAILURE: '@@previas/SET_BLACKLIST_UPLOAD_FAILURE',

  CLEAR_BLACKLIST_FILE: '@@previas/CLEAR_BLACKLIST_FILE',

  SET_ADD_BLACKLIST_REQUEST: '@@previas/SET_ADD_BLACKLIST_REQUEST',
  SET_ADD_BLACKLIST_SUCCESS: '@@previas/SET_ADD_BLACKLIST_SUCCESS',
  SET_ADD_BLACKLIST_FAILURE: '@@previas/SET_ADD_BLACKLIST_FAILURE',

  SET_BLACKLIST_BY_ID_REQUEST: '@@previas/SET_BLACKLIST_BY_ID_REQUEST',
  SET_BLACKLIST_BY_ID_SUCCESS: '@@previas/SET_BLACKLIST_BY_ID_SUCCESS',
  SET_BLACKLIST_BY_ID_FAILURE: '@@previas/SET_BLACKLIST_BY_ID_FAILURE',

  SET_REMOVE_BLACKLIST_REQUEST: '@@previas/SET_REMOVE_BLACKLIST_REQUEST',
  SET_REMOVE_BLACKLIST_SUCCESS: '@@previas/SET_REMOVE_BLACKLIST_SUCCESS',
  SET_REMOVE_BLACKLIST_FAILURE: '@@previas/SET_REMOVE_BLACKLIST_FAILURE',

  SET_BLACKLIST_ADD_COMMENT: '@@previas/SET_BLACKLIST_ADD_COMMENT',
  SET_BLACKLIST_ADD_COMMENT_SUCCESS: '@@previas/SET_BLACKLIST_ADD_COMMENT_SUCCESS',
  SET_BLACKLIST_ADD_COMMENT_FAILURE: '@@previas/SET_BLACKLIST_ADD_COMMENT_FAILURE',

  BLACKLIST_DOWNLOAD_FILE_REQUEST: '@@previas/BLACKLIST_DOWNLOAD_FILE_REQUEST',
  BLACKLIST_DOWNLOAD_FILE_SUCCESS: '@@previas/BLACKLIST_DOWNLOAD_FILE_SUCCESS',
  BLACKLIST_DOWNLOAD_FILE_FAILURE: '@@previas/BLACKLIST_DOWNLOAD_FILE_FAILURE',

  UPLOAD_FILE_REQUEST: '@@previas/UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_SUCCESS: '@@previas/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: '@@previas/UPLOAD_FILE_FAILURE',
  UPLOAD_PREVIAS_REQUEST: '@@previas/UPLOAD_PREVIAS_REQUEST',
  UPLOAD_PREVIAS_SUCCESS: '@@previas/UPLOAD_PREVIAS_SUCCESS',
  UPLOAD_PREVIAS_FAILURE: '@@previas/UPLOAD_PREVIAS_FAILURE',

  SET_ROBOTS_REQUEST: '@@previas/SET_ROBOTS_REQUEST',
  SET_ROBOTS_SUCCESS: '@@previas/SET_ROBOTS_SUCCESS',
  SET_ROBOTS_FAILURE: '@@previas/SET_ROBOTS_FAILURE',
  SET_CONTRATO_REQUEST: '@@previas/SET_CONTRATO_REQUEST',
  SET_CONTRATO_SUCCESS: '@@previas/SET_CONTRATO_SUCCESS',
  SET_CONTRATO_FAILURE: '@@previas/SET_CONTRATO_FAILURE',
  SET_DOCUMENTS_REQUEST: '@@previas/SET_DOCUMENTS_REQUEST',
  SET_DOCUMENTS_SUCCESS: '@@previas/SET_DOCUMENTS_SUCCESS',
  SET_DOCUMENTS_FAILURE: '@@previas/SET_DOCUMENTS_FAILURE',
  SET_PRORROGAR_REQUEST: '@@previas/SET_PRORROGAR_REQUEST',
  SET_PRORROGAR_SUCCESS: '@@previas/SET_PRORROGAR_SUCCESS',
  SET_PRORROGAR_FAILURE: '@@previas/SET_PRORROGAR_FAILURE',
  SET_FILTER_LIST_REQUEST: '@@previas/SET_FILTER_LIST_REQUEST',
  SET_FILTER_LIST_SUCCESS: '@@previas/SET_FILTER_LIST_SUCCESS',
  SET_FILTER_LIST_FAILURE: '@@previas/SET_FILTER_LIST_FAILURE',
  SET_REGISTER_UCA_REQUEST: '@@previas/SET_REGISTER_UCA_REQUEST',
  SET_REGISTER_UCA_SUCCESS: '@@previas/SET_REGISTER_UCA_SUCCESS',
  SET_REGISTER_UCA_FAILURE: '@@previas/SET_REGISTER_UCA_FAILURE',
  SET_CANCEL_CONTRATO_REQUEST: '@@previas/SET_CANCEL_CONTRATO_REQUEST',
  SET_CANCEL_CONTRATO_SUCCESS: '@@previas/SET_CANCEL_CONTRATO_SUCCESS',
  SET_CANCEL_CONTRATO_FAILURE: '@@previas/SET_CANCEL_CONTRATO_FAILURE',
  SET_CANCEL_TERM_CONFIDENTIALITY_REQUEST: '@@previas/SET_CANCEL_TERM_CONFIDENTIALITY_REQUEST',
  SET_CANCEL_TERM_CONFIDENTIALITY_SUCCESS: '@@previas/SET_CANCEL_TERM_CONFIDENTIALITY_SUCCESS',
  SET_CANCEL_TERM_CONFIDENTIALITY_FAILURE: '@@previas/SET_CANCEL_TERM_CONFIDENTIALITY_FAILURE',
  SET_RESEND_TERM_CONFIDENTIALITY_REQUEST: '@@previas/SET_RESEND_TERM_CONFIDENTIALITY_REQUEST',
  SET_RESEND_TERM_CONFIDENTIALITY_SUCCESS: '@@previas/SET_RESEND_TERM_CONFIDENTIALITY_SUCCESS',
  SET_RESEND_TERM_CONFIDENTIALITY_FAILURE: '@@previas/SET_RESEND_TERM_CONFIDENTIALITY_FAILURE',

  REFRESH_DOCS_REQUEST: '@@previas/REFRESH_DOCS_REQUEST',
  REFRESH_DOCS_SUCCESS: '@@previas/REFRESH_DOCS_SUCCESS',
  REFRESH_DOCS_SUCCESS_PJ: '@@previas/REFRESH_DOCS_SUCCESS_PJ',
  REFRESH_DOCS_FAILURE: '@@previas/REFRESH_DOCS_FAILURE',

  SEND_APROVAR_ALL_DOCUMENTS_REQUEST: '@@previas/SEND_APROVAR_ALL_DOCUMENTS_REQUEST',
  SEND_APROVAR_ALL_DOCUMENTS_SUCCESS: '@@previas/SEND_APROVAR_ALL_DOCUMENTS_SUCCESS',
  SEND_APROVAR_ALL_DOCUMENTS_FAILURE: '@@previas/SEND_APROVAR_ALL_DOCUMENTS_FAILURE',


  UPDATE_USER_PJ_REQUEST: '@@previas/UPDATE_USER_PJ_REQUEST',
  UPDATE_USER_PJ_SUCCESS: '@@previas/UPDATE_USER_PJ_SUCCESS',
  UPDATE_USER_PJ_FAILURE: '@@previas/UPDATE_USER_PJ_FAILURE',

  RESET_STATE_REQUEST: '@@previas/RESET_STATE_REQUEST',
  RESET_STATE_SUCCESS: '@@previas/RESET_STATE_SUCCESS',
  RESET_STATE_FAILURE: '@@previas/RESET_STATE_FAILURE',

  ARQUIVAR_PREVIA_REQUEST: '@@previas/ARQUIVAR_PREVIA_REQUEST',
  ARQUIVAR_PREVIA_SUCCESS: '@@previas/ARQUIVAR_PREVIA_SUCCESS',
  ARQUIVAR_PREVIA_FAILURE: '@@previas/ARQUIVAR_PREVIA_FAILURE',

  FINISH_PREVIA_REQUEST: '@@previas/FINISH_PREVIA_REQUEST',
  FINISH_PREVIA_SUCCESS: '@@previas/FINISH_PREVIA_SUCCESS',
  FINISH_PREVIA_FAILURE: '@@previas/FINISH_PREVIA_FAILURE',

  POST_ADD_BLACK_LIST_REQUEST: '@@previas/POST_ADD_BLACK_LIST_REQUEST',
  POST_ADD_BLACK_LIST_SUCCESS: '@@previas/POST_ADD_BLACK_LIST_SUCCESS',
  POST_ADD_BLACK_LIST_FAILURE: '@@previas/POST_ADD_BLACK_LIST_FAILURE',

  SEND_APROVAR_REPROVAR_DOCUMENTO_REQUEST: '@@previas/SEND_APROVAR_REPROVAR_DOCUMENTO_REQUEST',
  SEND_APROVAR_REPROVAR_DOCUMENTO_SUCCESS: '@@previas/SEND_APROVAR_REPROVAR_DOCUMENTO_SUCCESS',
  SEND_APROVAR_REPROVAR_DOCUMENTO_FAILURE: '@@previas/SEND_APROVAR_REPROVAR_DOCUMENTO_FAILURE',

  DELETE_FILE_CONSULTA_EXTERNA_REQUEST: '@@previas/DELETE_FILE_CONSULTA_EXTERNA_REQUEST',

  APROVAR_ANALISE_PF_REQUEST: '@@previas/APROVAR_ANALIST_PF_REQUEST',
  APROVAR_ANALISE_PF_SUCCESS: '@@previas/APROVAR_ANALIST_PF_SUCCESS',
  APROVAR_ANALISE_PF_FAILURE: '@@previas/APROVAR_ANALIST_PF_FAILURE',

  REPROVAR_ANALISE_PF_REQUEST: '@@previas/REPROVAR_ANALIST_PF_REQUEST',
  REPROVAR_ANALISE_PF_SUCCESS: '@@previas/REPROVAR_ANALIST_PF_SUCCESS',
  REPROVAR_ANALISE_PF_FAILURE: '@@previas/REPROVAR_ANALIST_PF_FAILURE',
}

export interface IPreviasState {
  loadingPrevias: boolean
  loadingDocument: boolean
  loadingUploadConsultaExterna: boolean

  _id: string
  dataRegistro: string
  unidade: string
  lojista: string
  bu: string
  status: string
  sla: string
  quinzeDias: boolean
  trintaDias: boolean
  desistencia: boolean
  matriculas: any

  hasTerm: boolean
  term: any
  resendTerm: boolean

  hasUca: boolean

  createUserUca?: boolean

  uca?: Uca

  hasContrato: boolean

  contract: any
  backstage_flags: BackStageFlags
  config: {
    docTypes: DocTypes[]
    witness: Witness
  }

  pf: User

  hasPj: boolean
  pj: UserPj

  emailCorpo: EmailCorporativo
  serviceUnit: ServiceUnit[]

  preMatricula?: {
    cover: string
    matricula: string
    senha: string
  }

  list: ListState
  listNotFiltred: ListState
  listRelatorio: ListRelatorioState
  userDetailTerm: UserTermState
  historyList: HistoryState
  sendComment: SendCommentState
  units: UnitsState
  uploadPrevias: UploadPrevias
  documents: DocumentsState
  sendCommentsStatus: SendCommentStatusState
  // userPj: UserPjState
  uclaFlag: UclaFlagState
  witness: WitnessState
  updateUserPj: UpdateUserPjState
  prorrogar: ProrrogarState
  banks: BanksState
  setDocument: SetDocumentStatus
  robots: any
  robotsList: any
  minuta: any
  arquivarPrevia: ArquivarPreviaState
  contratoDetail: ContratoDetailState
  cancelContrato: any
  finishPrevia: any
  blackListPrevia: any
  termConfidentialityDetail: TermConfidentialityState
  termConfidentialityCancel: CancelTermConfidentialityState
  newconValidationCandidato: any

  motivosPadrao: any
  blacklist: Blacklist
  blacklistDetail: BlacklistHistoricDetail
}

interface Uca {
  docNotApproved: any[]
  docRefused: any[]
  docsRequiredCount: number
  modalContrato: boolean
  modalContratoDetalhes: boolean
  porcentage: number
}

interface EmailCorporativo {
  email: string
  password: string
}

interface ServiceUnit {
  cep: string
  cidade: string
  idUnidade: string
  uf: string
  unidade: string
  _id: string
}

interface Blacklist {
  totalPage: number
  data: {
    id: string
    cpfCnpj: string
    name: string
    active: boolean
    user: string
    createdAt: string
    updatedAt: string
  }[]
  loading: boolean
  uploadLoading: boolean
  file: any
}

interface BlacklistHistoric {
  blacklist: boolean
  _id: string
  user: string
  date: string
  description: string
  file: string
}

interface BlacklistDetail {
  _id: string
  active: boolean
  cpfCnpj: string
  name: string
  user: string
  historic: BlacklistHistoric[]
  createdAt: string
  updatedAt: string
}

interface Status {
  _id: string
  ordem: number
  nome: string
  sla_dias: number
}

interface BlacklistPrevia {
  _id: string
  status: Status
  createdAt: string
  updatedAt: string
}

interface BlacklistHistoricDetail {
  data: {
    blacklist: BlacklistDetail
    previas: BlacklistPrevia[]
  }
  loading: boolean
}
