import { makeStyles } from '@material-ui/core'

export const style = makeStyles(() => ({
  candidateData: {
    top: '0',
    right: '25px',
    width: '23rem',
    height: '100%',
    padding: '80px 2rem',
    position: 'fixed',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    overflowY: 'auto',
  },

  candidateDataAlternative: {
    top: '0',
    right: '25px',
    width: '30rem',
    height: '100%',
    padding: '80px 2rem',
    position: 'fixed',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    overflowY: 'auto',
  },

  colorTextStatus: {
    textAlign: 'center',
    padding: 6,
    borderRadius: 6,
  },

  candidateDataTitle: {
    display: 'flex',
    gap: 6,
    alignItems: 'flex-end',
    justifyContent: 'left',
    margin: '1.2rem 0',
    color: '#737372',
    fontSize: '1.4rem',

    '& h5': {
      fontSize: '1rem',
      fontWeight: 400,
      color: '#737372',
    },
  },
  textOverflow: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  candidateDataSubtitle: {
    color: '#3A3939',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  candidateDataSubtitleAlternative: {
    color: '#3A3939',
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  shopkeeperBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F1F1F1',
  },
  shopkeeperData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  shopkeeper: {
    maxWidth: 250,
    padding: '0 4px',
    borderRadius: 4,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    backgroundColor: '#F1F1F1',
  },
  shopkeeperIcon: {
    width: '1.75rem',
    padding: '0 8px',
    cursor: 'pointer',
  },
  statusBox: {
    marginTop: '1rem',
    display: 'inline-grid',
  },
  refresh: {
    width: 190,
    height: 40,
    backgroundColor: '#CB2D1B',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderColor: 'transparent',
    marginTop: 16,
  },
  titleRefresh: {
    color: 'white',
    fontSize: 16,
  },
  titleColor: {
    color: '#525151',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '32px',
    border: 1,
    borderStyle: 'solid',
    padding: 8,
    paddingLeft: 4,
    borderColor: '#f1f1f1',
  },
  refreshSumitPF: {
    width: 315,
    height: 40,
    backgroundColor: '#CB2D1B',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderColor: 'transparent',
    marginTop: 16,
  },
  ucaPercentage: {
    zIndex: 2,
    display: 'block',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    color: 'white',
    top: '-1px',
    fontWeight: 'bold',
    fontSize: 12,
  },
}))
